<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/${resource.route}/index`)">Back</sc-button>
        </div>
        <div class="pd">
            <sc-form :form="form" @submit="submit()" v-if="form">


                <template #field-player_groups="{row}">
                    <sc-form-field :form="form" field="player_groups">
                        <nr-radio style="font-size:0.9em" :value.sync="form.fields.player_groups" :multi="true"
                                  :options="playergroups">
                        </nr-radio>
                    </sc-form-field>
                </template>

                <template #field-metadataValue="{row}">

                    <div class="metadata-fields">
                        <div class="metadata-header">
                            Player Metadata
                        </div>

                        <div class="metadata-row" v-for="meta in metadata" v-if="meta.id !== 3">

                            <div class="metadata-label">{{ meta.attributes.name }}</div>
                            <template v-if="meta.attributes.valueType === 'ANY'">
                                <input v-model="metadataResults[meta.id]"/>
                            </template>
                            <template v-else-if="meta.attributes.valueType === 'PICKLIST'">
                                <select v-model="metadataResults[meta.id]">
                                    <option v-for="option in meta.attributes.predefinedValues" :value="option.id">
                                        {{ option.value }}
                                    </option>
                                </select>
                            </template>
                            <template v-else-if="meta.attributes.valueType === 'BOOLEAN'">
                                <select v-model="metadataResults[meta.id]">
                                    <option :value="true">True</option>
                                    <option :value="false">False</option>
                                </select>
                            </template>
                            <template v-else>
                                Type not defined
                            </template>

                        </div>
                    </div>

                </template>

            </sc-form>
        </div>
    </div>

</template>

<script>
export default {
  name: "new",

  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      words: '',
      form: null,
      playergroups: [],
      metadata: [],
      metadataResults: {}
    }
  },

  computed: {
    resource() {
      return this.passed
    }
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `New ${this.resource.name}`,
      url: this.resource.api,
      fields: {
        name: {},
        description: {},
        operator_id: {
          name: 'Operator',
          component: 'nr-select',
          options: {
            resource: 'operator?amount=10000',
            empty: true
          }
        },
        channel_id: {
          name: 'Channel',
          component: 'nr-select',
          options: {
            resource: 'channels?amount=10000&relations=exclude',
            empty: true,
          }
        },
        metadataValue: {
          name: 'Player metadata',
          default: []
        },
        player_groups: {
          default: []
        },
      },
      success: () => {
        this.$notify.success(`${this.resource.name} added`)
        this.$router.push(`/app/${this.resource.route}/index`)
      }
    });

    this.fetchPlayerGroups();
    this.fetchMetadata();
  },

  methods: {
    submit() {

      this.form.fields.metadataValue = [];
      let meta = this.metadataResults;
      for (const [key, val] of Object.entries(meta)) {
        // skip the ID metadata field
        if (key === '3') {
          continue
        }
        // only add the fields that are filled in
        if (val === '') {
          continue;
        }
        this.form.fields.metadataValue.push({
          playerMetadata_id: parseInt(key),
          value: val
        })
      }

      this.form.fields['user_groups'] = []
      this.form.post();
    },

    fetchPlayerGroups() {
      this.$talker.api('player-groups?amount=10000')
        .then(res => {
          this.playergroups = res.data.data.map(x => {
            return {
              label: x.attributes.name,
              value: x.id
            }
          });
        })
    },

    fetchMetadata() {
      this.$talker.api('player-metadata?amount=10000')
        .then(res => {
          res.data.data.forEach(x => this.metadataResults[x.id] = '')
          this.metadata = res.data.data
        })
    }
  }
}
</script>

<style scoped lang="scss">

.metadata-fields {
    margin-bottom: 2em;

    .metadata-header {
        color: #2d97cb;
        margin-bottom: 0.5em;
        font-weight: 700;
    }

    .metadata-row {
        margin-bottom: 1em;
        margin-left: 2em;
    }

    .metadata-label {
        color: #2d97cb;
        font-weight: 700;
        margin-bottom: 0.3em;
    }
}


</style>